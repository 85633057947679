import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const postFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });

  return res.data;
};

export const getFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const fetcher = getFetcher;

// ----------------------------------------------------------------------

export const endpoints = {

  // legagcy template endpoints under /api, to be removed later
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  _auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },

  // tmms endpoints
  auth: {
    me: '/auth/me',
    login: '/auth/login',
    register: '/auth/register',
  },
  appointmentStatuses: '/appointment-statuses',
  appointmentTypes: '/appointment-types',
  attachmentLists: '/attachment-lists',
  attachments: '/attachments',
  attachmentShares: '/attachment-shares',
  cabinTypes: '/cabin-types',
  changes: '/changes',
  cities: '/cities',
  contacts: '/contacts',
  customerContacts: '/customer-contacts',
  customers: '/customers',
  dashboardCreatedAndDelivered: 'dashboard-created-and-delivered',
  dashboardCustomerPrenotes: '/dashboard-customer-prenotes',
  dashboardDropped: '/dashboard-dropped',
  dashboardIncompleteTasks: '/dashboard-incomplete-tasks',
  dashboardInvoices: '/dashboard-invoices',
  dashboardParked: '/dashboard-parked',
  dashboardPrenotes: '/dashboard-prenotes',
  dashboardPrepulled: '/dashboard-prepulled',
  dashboardUJPMs: '/dashboard-ujpms',
  dashboardUtilized: '/dashboard-utilized',
  defaultGridFilters: '/default-grid-filters',
  deliveryTypes: '/delivery-types',
  dispatchedTrips: '/dispatched-trips',
  dispatchedTripsGridItems: '/dispatched-trips-grid',
  distancePreferences: '/distance-preferences',
  driverAdditions: '/driver-addition',
  driverDeductions: '/driver-deductions',
  driverPaymentAdditions: '/driver-payment-additions',
  driverPaymentDeductions: '/driver-payment-deductions',
  driverPaymentHeaders: '/driver-payment-headers',
  driverPaymentItems: '/driver-payment-items',
  driverPayments: '/driver-payments',
  driverProfiles: '/driver-profiles',
  driverStatuses: '/driver-statuses',
  driverTypes: '/driver-types',
  expenseTypes: '/expense-types',
  groups: '/groups',
  invoiceDetails: '/invoice-details',
  invoiceItems: '/invoice-items',
  invoices: '/invoices',
  invoiceStatuses: '/invoice-statuses',
  loadingTypes: '/loading-types',
  locationContacts: '/location-contacts',
  locationZones: '/location-zones',
  locations: '/locations',
  notes: '/notes',
  notificationLogs: '/notification-logs',
  notificationTemplateRenderer: '/notification-template-renderer',
  notificationTemplates: '/notification-templates',
  payTypes: '/pay-types',
  permissions: '/permissions',
  recentlyViewedShipments: '/recently-viewed-shipments',
  reportParameters: '/report-parameters',
  reports: '/reports',
  revenueTypes: '/revenue-types',
  shipmentDeliveries: '/shipment-deliveries',
  shipmentExpenses: '/shipment-expenses',
  shipmentGridItems: '/shipments-grid',
  shipmentRevenues: '/shipment-revenues',
  shipments: '/shipments',
  shipmentStatuses: '/shipment-statuses',
  shipmentTrips: '/shipment-trips',
  shipmentTypes: '/shipment-types',
  states: '/states',
  steamships: '/steamships',
  terminals: '/terminals',
  trailerTypes: '/trailer-types',
  tripStatuses: '/trip-statuses',
  tripTypes: '/trip-types',
  truckColors: '/truck-colors',
  truckMakes: '/truck-makes',
  trucks: '/trucks',
  truckStatuses: '/truck-statuses',
  truckTransmissionTypes: '/truck-transmission-types',
  truckTypes: '/truck-types',
  undispatchedTrips: '/undispatched-trips',
  undispatchedTripsGridItems: '/undispatched-trips-grid',
  unpaidShipments: '/unpaid-shipments',
  userGridFilters: '/user-grid-filters',
  users: '/users',
  vendors: '/vendors',
};
